import { RouteRecordRaw } from "vue-router";
import routesName from "@/utils/constants/routesName";
import EmptyLayout from "@/layouts/EmptyLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: routesName.dashboard,
    redirect: {
      name: routesName.courses,
    },
    meta: { protected: true },
  },
  {
    path: "/login",
    name: routesName.login,
    component: () => import("@/views/LoginView.vue"),
    meta: { notAuthorized: true, layout: EmptyLayout },
  },
  {
    path: "/courses",
    name: routesName.courses,
    component: () => import("@/views/CoursesView.vue"),
    meta: { protected: true, title: "Courses" },
  },
  {
    path: "/course/create",
    name: routesName.courseCreate,
    component: () => import("@/views/CourseCreateView.vue"),
    meta: { protected: true, title: "Create course" },
  },
  {
    path: "/users",
    name: routesName.users,
    component: () => import("@/views/UsersView.vue"),
    meta: { protected: true, title: "Users" },
  },
  {
    path: "/user/:id",
    name: routesName.singleUser,
    component: () => import("@/views/SingleUserView.vue"),
    meta: { protected: true, title: "User data" },
  },
  {
    path: "/blog",
    name: routesName.blog,
    component: () => import("@/views/BlogView.vue"),
    meta: { protected: true, title: "Blog" },
  },
  {
    path: "/:pathMatch(.*)*",
    name: routesName.notFound,
    meta: { protected: true },
    component: () => import("@/views/NotFoundView.vue"),
  },
];

export default routes;
