import { defineStore } from "pinia";
import { ref } from "vue";
import { IProfile } from "@/types/entities/IProfile";
import router from "@/router";
import routesName from "@/utils/constants/routesName";

export const useUserStore = defineStore(
  "user",
  () => {
    const accessToken = ref<string>("");
    const refreshToken = ref<string>("");
    const userData = ref<IProfile | null>(null);

    const setTokens = (payload: {
      accessToken: string;
      refreshToken: string;
    }): void => {
      accessToken.value = payload.accessToken;
      refreshToken.value = payload.refreshToken;
    };

    const setUserProfile = (profile: IProfile): void => {
      userData.value = profile;
    };

    const clear = (): void => {
      accessToken.value = "";
      refreshToken.value = "";
      userData.value = null;
      router.push({ name: routesName.login });
    };

    return {
      accessToken,
      refreshToken,
      userData,
      setTokens,
      setUserProfile,
      clear,
    };
  },
  {
    persist: true,
  }
);
