import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "@/scss/styles.scss";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { PiniaSharedState } from "pinia-shared-state";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { md2 } from "vuetify/blueprints";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(PiniaSharedState({ enable: true, initialize: true, type: "native" }));

const vuetify = createVuetify({
  components,
  directives,
  blueprint: md2,
  defaults: {
    VTextField: { variant: "outlined" },
  },
});

const app = createApp(App);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: "https://3cd14e697efd9f4409b82adc43655dd5@sentry.onix.team/229",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "onix-systems-nexusmedx-admin.staging.onix.ua/",
          /^\//,
        ],
      }),
    ],
    tracesSampleRate: 1.0,
    environment: "development",
    logErrors: true,
  });
}

app.use(pinia).use(router).use(vuetify).mount("#app");
