const routesName = {
  dashboard: "dashboard",
  login: "login",
  courses: "courses",
  courseCreate: "courseCreate",
  courseEdit: "courseEdit",
  users: "users",
  singleUser: "singleUser",
  blog: "blog",
  notFound: "notFound",
};

export default routesName;
